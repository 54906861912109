<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('editBooking') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item v-if="counting" lines="full">
      <ion-icon :color="clockColor" :md="timeOutline" :ios="timeOutline" slot="start" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
      <vue-countdown slot="start" @progress="checkTime" :time="3 * 60 * 1000" v-slot="{ minutes, seconds }">
        {{ $t('timerRunning') }}  <strong>{{ minutes }}:{{ seconds }}</strong>
      </vue-countdown>
    </ion-item>
    <ion-item v-if="!counting" lines="full">
      <ion-label class="ion-text-wrap"><strong>{{ $t('timerStopped') }}</strong></ion-label>
    </ion-item>
    <ion-card>
      <ion-card-header>
        <ion-card-title v-if="slot">
          {{ dayjs(slot.date).tz().format('DD.MM.YYYY HH:mm') }}
        </ion-card-title>
        <ion-label>{{ $t('freeSlots') }}: {{ selectedTeetime.maxPersonsBookable - slot.bookingPersons.length }}</ion-label>
      </ion-card-header>
      <ion-list lines="full" class="ion-no-margin">

        <ion-grid>
          <ion-row v-for="(item, index) in flightPlayers" :key="index" :value="item">
            <ion-grid>
              <ion-row>
                <ion-col style="border-top: 1px solid lightgrey;" size="auto">
                  <ion-icon v-if="selectedTeetime" @click="deletePlayer(item)" color="danger" :md="trashOutline" :ios="trashOutline" style="zoom: 1.4; margin-right: 5px;"></ion-icon>
                </ion-col>
                <ion-col style="border-top: 1px solid lightgrey;" >
                  <ion-label style="font-weight: bold; font-size: 16px;" class="ion-text-wrap">&nbsp;{{ item.lastName }} {{ item.firstName }} ({{item.hcp > 54 ? '--' : item.hcp }}) {{ !item.hasAbo && selectedTeetime && (selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice) ? (item.show9Hole && slot.allowPrice9L ? item.price9L.toFixed(2) : item.price.toFixed(2)) : '' }} {{ !item.hasAbo ? (selectedTeetime && (selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice) ? slot.currencySymbol : '') : (selectedTeetime && (selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice) ? 'Abo' : '') }}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row >
                <ion-col size="auto" v-if="selectedTeetime && selectedTeetime.showDog && slot.showDog">
                  <ion-icon v-if="selectedTeetime && selectedTeetime.showDog" :md="pawSharp" :ios="pawSharp" style="zoom: 1.4; margin-right: 0px;"></ion-icon>
                  <ion-checkbox v-model="item.withDog" color="primary"></ion-checkbox>
                </ion-col>
                <ion-col size="auto" v-if="selectedTeetime && selectedTeetime.show9Hole && (slot.show9Hole == undefined || slot.show9Hole == true)">
                  <svg v-if="selectedTeetime && selectedTeetime.show9Hole" width="22" height="30">
                    <circle cx="10" cy="10" r="10" fill="#eb445a" />
                    <text x="45%" y="35%" text-anchor="middle" fill="white" font-size="12px" font-family="Arial" dy=".3em">9</text>
                  </svg>          
                  <ion-checkbox v-model="item.show9Hole" color="primary"></ion-checkbox>
                </ion-col>
                <ion-col size="auto" v-if="selectedTeetime && selectedTeetime.showPlayWithMe">
                  <ion-icon v-if="selectedTeetime && selectedTeetime.showPlayWithMe" color="member" :md="heartOutline" :ios="heartOutline" style="zoom: 1.4; margin-right: 0px;"></ion-icon>
                  <ion-checkbox v-model="item.showPlayWithMe" color="primary"></ion-checkbox>
                </ion-col>
                <ion-col size="auto" v-if="selectedTeetime && selectedTeetime.greenfeePaymentEnabled">
                  <ion-icon v-if="selectedTeetime && selectedTeetime.greenfeePaymentEnabled" :md="cashOutline" :ios="cashOutline" style="zoom: 1.4; margin-left: 5px;"></ion-icon>
                  <ion-checkbox style="margin-left: 2px;" :disabled="(item.forceGreenfeePayment && item.payGreenfee) || item.price <= 0" v-model="item.payGreenfee" ></ion-checkbox>
                  <ion-label style="margin-left: 2px;">{{ $t('directPayment') }}</ion-label>
                </ion-col>
             </ion-row>
            </ion-grid>
          </ion-row>
        </ion-grid>
        <!-- <ion-item v-for="(item, index) in flightPlayers" :key="index" :value="item">
            <ion-icon v-if="selectedTeetime" @click="deletePlayer(item)" color="danger" :md="trashOutline" :ios="trashOutline" slot="start" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
            <ion-label class="ion-text-wrap" slot="start">&nbsp;{{ item.lastName }} {{ item.firstName }} ({{item.hcp}}) {{ !item.hasAbo && selectedTeetime && (selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice) ? (item.show9Hole && slot.allowPrice9L ? item.price9L.toFixed(2) : item.price.toFixed(2)) : '' }} {{ !item.hasAbo ? (selectedTeetime && (selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice) ? slot.currencySymbol : '') : (selectedTeetime && (selectedTeetime.greenfeePaymentEnabled || selectedTeetime.showGreenfeePrice) ? 'Abo' : '') }}</ion-label>
            <ion-checkbox v-if="selectedTeetime && selectedTeetime.showDog" v-model="item.withDog" color="primary" slot="end"></ion-checkbox>
            <ion-checkbox v-if="selectedTeetime && selectedTeetime.show9Hole" v-model="item.show9Hole" color="danger" slot="end"></ion-checkbox>
            <ion-checkbox v-if="selectedTeetime && selectedTeetime.showPlayWithMe" v-model="item.showPlayWithMe" color="member" slot="end"></ion-checkbox>
            <ion-checkbox v-if="selectedTeetime && selectedTeetime.greenfeePaymentEnabled" :disabled="(item.forceGreenfeePayment && item.payGreenfee) || item.price <= 0" v-model="item.payGreenfee" slot="end"></ion-checkbox>
        </ion-item>-->
        <ion-item  style="border-top: 1px solid lightgrey;" v-if="selectedTeetime && !slot.isPast && slot.carts.filter(f => f.isAvailable).length > 0">
          <ion-icon slot="start" color="success" :md="carOutline" :ios="carOutline"></ion-icon>
            <ion-select slot="end" multiple="true" v-model="selectedCart" @ionChange="cartChanged()">
              <ion-select-option v-for="(item, index) in slot.carts.filter(f => f.isAvailable)" :key="index" :value="item">{{ item.name }} {{ selectedTeetime && selectedTeetime.showCartfeePrice ? (' (' + item.price + ')') : '' }}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item-divider v-if="flightPlayers && flightPlayers.length < 4">
          <ion-label>
            {{ $t('addAdditionalFriends') }}
          </ion-label>
        </ion-item-divider>

        <ion-item v-if="flightPlayers && flightPlayers.length < 4">
          <ion-icon color="primary" :md="personAddOutline" :ios="personAddOutline"></ion-icon>
          <ion-select interface="action-sheet" slot="end" v-model="selectedFriend" @ionChange="addFriend($event.target.value)">
            <ion-select-option v-for="(item, i) in friendsSelect" :key="i" :value="item">{{ item.nameLong }}</ion-select-option>
          </ion-select>
          </ion-item>

        <ion-item-divider v-if="flightPlayers && flightPlayers.length < 4">
          <ion-label>
            {{ $t('addPerson') }}
          </ion-label>
        </ion-item-divider>
        <ion-item v-if="flightPlayers && flightPlayers.length < 4" lines="full">
          <ion-icon v-if="selectedTeetime.allowmanualperson" @click="addManualPerson()" slot="start" color="success" :md="personAddOutline" :ios="personAddOutline" style="zoom: 1.0"></ion-icon>
          <ion-icon v-else slot="start" color="primary" :md="personAddOutline" :ios="personAddOutline" style="zoom: 1.0"></ion-icon>
          <ion-searchbar :placeholder="$t('searchPlayer')" v-model="searchText" @ionClear="findPlayer('')" @input="findPlayer($event.target.value)"></ion-searchbar>
        </ion-item>
        <ion-item v-if="playersFound && playersFound.length > 0">
          <ion-list>
            <ion-list-header>
              {{ $t('searchresult') }}
            </ion-list-header>
            <ion-item class="ion-text-wrap" v-for="(player, i) in playersFound" :key="i" @click="addPlayer(player)">
              <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
              <ion-label class="ion-text-wrap" style="margin-left: 5px">
                <h2>{{ player.nameLong }}</h2>
              </ion-label>
              <ion-avatar @contextmenu.prevent="" slot="end" v-if="player.showAvatar && player.avatarLink != ''">
                <img :src="player.avatarLink">
              </ion-avatar>
            </ion-item>
          </ion-list>
        </ion-item>

        <ion-item v-if="slot && pricetotal > 0">
          <h3>{{ $t('amountToPay') }}: {{ pricetotal }} {{ slot.currencySymbol }}</h3>
        </ion-item>

        <ion-list v-show="slot && pricetotal > 0">
          <div style="margin-top: 5px;" slot="start" v-show="counting && !(buttonDisabled || (flightPlayers && (flightPlayers.length > 4 || flightPlayers.length <= 0)|| (selectedTeetime && flightPlayers && selectedTeetime.maxPersonsBookable - slot.bookingPersons.length - flightPlayers.length < 0)))">
            
            <ion-item v-if="(clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canLastschriftTeetime)">
              <ion-label slot="start">{{ $t('payLastschrift')}}</ion-label>
              <ion-checkbox v-model="lastschriftChecked" slot="end"></ion-checkbox>
            </ion-item>
            
            <ion-item v-if="lastschriftChecked">
              <ion-label class="ion-text-wrap">{{ $t('payLastschriftHint')}}</ion-label>
              <ion-button @click="createBookingByLastschrift()" color="success">{{ $t('bookAndPay') }}</ion-button>
            </ion-item>

            
            <ion-item v-show="!lastschriftChecked">
              <div  id="paypalButton"></div>
            </ion-item>
          </div>
<!--           <ion-select style="min-width: 100% !important;" interface="popover" v-model="paymenttype" :value="paymenttype">
            <ion-select-option v-if="isSuccessfullBraintreePaypalAuthorized" value="PayPalAccount">{{ $t('paypalPayment') }}</ion-select-option>  
            <ion-select-option v-if="isSuccessfullBraintreeCreditCardAuthorized" value="CreditCard">{{ $t('creditCardPayment') }}</ion-select-option>  
          </ion-select>-->
        </ion-list>
        <ion-button v-if="counting && pricetotal == 0" :disabled="buttonDisabled || (flightPlayers && (flightPlayers.length > 4 || flightPlayers.length <= 0)|| (selectedTeetime && flightPlayers && selectedTeetime.maxPersonsBookable - slot.bookingPersons.length - flightPlayers.length < 0))" style="margin-top: 15px;" expand="block" color="primary" @click="doBooking()">{{ pricetotal > 0 ? $t('makeBookingAndPay') : $t('makeBooking') }}</ion-button>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,  IonSearchbar, loadingController,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonListHeader, IonAvatar,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, IonSelect, IonSelectOption, isPlatform } from '@ionic/vue';
import { defineComponent, ref, toRef } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, womanSharp, manSharp,
          personAddOutline, trashOutline, timeOutline, carOutline, cashOutline, heartOutline, personOutline } from 'ionicons/icons';
import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import createBookingTypeBookingMutationFile from '../../graphql/createBookingTypeBooking.mutation.gql'
import createPaymentMutationFile from '../../graphql/createPayment.mutation.gql'
import findPlayersQueryFile from '../../graphql/findPlayers.query.gql'
import findManualPlayerGreenfeeQueryFile from '../../graphql/findManualPlayerGreenfee.query.gql'
import findFriendsQueryFile from '../../graphql/findFriends.query.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import store from '../../main'
import translate from '@/plugins/translate'
import AddPersonManual from './AddPersonManual'
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'EditBookingDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonSelect, IonSelectOption, IonSearchbar, IonListHeader, IonAvatar, 
              },
  props: ['propsData'],
  setup(props) {
    let slot = props.propsData.slot
    let bookingTypeId = props.propsData.bookingTypeId
    let selectedCart = ref()
    let selectedTeetime = props.propsData.selectedTeetime
    let counting = ref(true)
    let buttonDisabled = ref(false)
    let clockColor = ref('success')
    let searchText = ref('')
    let flightPlayers = props.propsData.flightPlayers
    let play18Holes = props.propsData.play18Holes
    //let pricesum = ref(0)
    let pricecarts = ref(0)
    let friendsSelect = ref([])
    const selectedFriend = ref(null)
    const clubInfo = ref(getClubInfo())
    const lastschriftChecked = ref(false)

    const { mutate: createBookingTypeBookingMutation } = useMutation(createBookingTypeBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutation } = useMutation(createPaymentMutationFile, { fetchPolicy: 'no-cache' });

    const { refetch: findPlayersRefetch, result: findPlayersResult } = useQuery(findPlayersQueryFile, () => ({ searchTerm: '' }))
    const playersFound = useResult(findPlayersResult, null, data => data.findPlayers.players)

    const { result: findManualPlayerGreenfeeResult } = useQuery(findManualPlayerGreenfeeQueryFile, () => ({ searchTerm: '', bookingTypeId: bookingTypeId, slotDate: slot.date, checkPayment: true }))
    const manualPlayerFound = useResult(findManualPlayerGreenfeeResult, null, data => data.findManualPlayerGreenfee.players)

    const { onResult, result: findFriendsResult } = useQuery(findFriendsQueryFile, { bookingTypeId: bookingTypeId, slotDate: slot.date, checkPayment: true }, { fetchPolicy: 'no-cache' })
    const friends = useResult(findFriendsResult, null, data => data.findFriends.players)

    onResult(queryResult => {
      if (queryResult && queryResult.data && queryResult.data.findFriends && queryResult.data.findFriends.players) {
        friendsSelect.value = queryResult.data.findFriends.players.filter((p) => p.confirmed == true)
      }
    })

    return {
      createBookingTypeBookingMutation, flightPlayers, playersFound, searchText, cashOutline, createPaymentMutation,
      slot, bookingTypeId, selectedTeetime, dayjs, womanSharp, manSharp, buttonDisabled, heartOutline,
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline,
      trashOutline,timeOutline, counting, clockColor, carOutline, selectedCart, findPlayersRefetch, pricecarts, friendsSelect,
      selectedFriend, isPlatform, manualPlayerFound, personOutline, clubInfo, lastschriftChecked, play18Holes
    }
  },
  mounted() {
    if (!this.slot.userAllowedToBook && this.slot.bookingPersons.find(f => f.isMyBooking))
      this.flightPlayers.splice(this.flightPlayers.findIndex(p => p.id == this.$store.getters.UserData.personId), 1)

    loadScript({ "client-id": this.clubInfo.clubInfo.paypalClientID, currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          const p = await this.createPaymentMutation({ description: 'Startzeiten', amount: parseFloat(this.pricetotal), paymentType: 'payPal' })
          if (!p.data.createPayment.success) {
            return ''
          }
          return p.data.createPayment.token
         },
         onError: async () => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
            this.buttonDisabled = true
            loadingController.create().then(res => {
              res.present()
            }).catch(err => { })
            this.createBookingTypeBookingMutation({ dateSlot: this.slot.date, bookingTypeId: this.bookingTypeId, players: JSON.stringify(this.flightPlayers), useFlightIds: false, carts: JSON.stringify(this.selectedCart), paymentType: 'PayPalAccount',  language: this.$store.getters.language, orderId: data.orderID })
            .then(res => {
                toastController
                  .create({
                    message: this.$t('bookingAdded'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
                toastController
                  .create({
                    message: this.$t('notSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
            }) 
         },
         onCancel: async () => {
          await this.createPaymentMutation({ amount: parseFloat(this.pricetotal), paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      paymenttype : function(){ 
        return 'PayPalAccount'
/*        if (this.isSuccessfullBraintreePaypalAuthorized && this.isSuccessfullBraintreeCreditCardAuthorized )
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreePaypalAuthorized)
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreeCreditCardAuthorized)
          return 'CreditCard' */
       },
      pricetotal: function(){ 
        if (this.selectedTeetime && !this.selectedTeetime.cartPaymentEnabled)
          this.pricecarts = 0
        return (this.pricesum + this.pricecarts).toFixed(2) 
      },
      pricesum: function(){
        if (this.selectedTeetime && !this.selectedTeetime.greenfeePaymentEnabled)
          return 0
        if (this.slot.allowPrice9L) {
          let sum9L = 0
          let sum18L = 0
          if (this.flightPlayers && this.flightPlayers.filter(f => f.payGreenfee && !f.hasAbo && f.show9Hole).length > 0)
            sum9L = this.flightPlayers.filter(f => f.payGreenfee && !f.hasAbo && f.show9Hole).map(p => p.price9L).reduce((price9L, p) => p + price9L)
          if (this.flightPlayers && this.flightPlayers.filter(f => f.payGreenfee && !f.hasAbo && !f.show9Hole).length > 0)
            sum18L = this.flightPlayers.filter(f => f.payGreenfee && !f.hasAbo && !f.show9Hole).map(p => p.price).reduce((price, p) => p + price)
          return sum9L + sum18L
        }
        else if (this.flightPlayers && this.flightPlayers.filter(f => f.payGreenfee && !f.hasAbo).length > 0)
          return this.flightPlayers.filter(f => f.payGreenfee && !f.hasAbo).map(p => p.price).reduce((price, p) => p + price)
        else return 0
      }
  },
  methods: {
    async addManualPerson() {
      const modal = await modalController
        .create({
          component: AddPersonManual,
          cssClass: this.isPlatform('mobile') ? '' : 'editBooking-modal',
        })
      modal.onDidDismiss()
        .then((data) => {
          if (data.data) {
            this.flightPlayers.push({ id: this.manualPlayerFound[0].id, email: data.data.email, gender: data.data.gender, firstName: data.data.name, lastName: data.data.lastname, hcp: data.data.hcp, show9Hole: !this.play18Holes, showPlayWithMe: false, withDog: false, price: this.manualPlayerFound[0] ? this.manualPlayerFound[0].price : 0, price9L: this.manualPlayerFound[0] ? this.manualPlayerFound[0].price9L : 0, payGreenfee: this.manualPlayerFound[0] ? this.manualPlayerFound[0].payGreenfee : false, forceGreenfeePayment: this.manualPlayerFound[0] ? this.manualPlayerFound[0].forceGreenfeePayment : false, hasAbo: false })
          }
        })
      return modal.present();
    },
    addFriend(friend) {
      if (!this.selectedFriend || (this.flightPlayers && this.flightPlayers.findIndex(p => p.id == this.selectedFriend.id) >= 0)) {
          if (this.selectedFriend)
            toastController
              .create({
                message: this.selectedFriend.firstName + ' ' + this.selectedFriend.lastName + this.$t('alreadyInFlight'),
                duration: 3000,
                color: 'warning'
              }).then(res => res.present())
        this.selectedFriend = null
        return
      }
      this.flightPlayers.push({ id: friend.id, firstName: friend.firstName, lastName: friend.lastName, hcp: friend.hcp, show9Hole: !this.play18Holes, showPlayWithMe: false, withDog: false, price: friend.price, price9L: friend.price9L, payGreenfee: friend.payGreenfee, forceGreenfeePayment: friend.forceGreenfeePayment, hasAbo: friend.hasAbo })

      this.selectedFriend = null

    },
    async addPlayer(player) {
      if (this.flightPlayers && this.flightPlayers.findIndex(p => p.id == player.id) >= 0) {
         toastController
            .create({
              message: player.firstName + ' ' + player.lastName + this.$t('alreadyInFlight'),
              duration: 3000,
              color: 'warning'
            }).then(res => res.present())
        return
      }
      this.flightPlayers.push({ id: player.id, firstName: player.firstName, lastName: player.lastName, hcp: player.hcp, show9Hole: !this.play18Holes, showPlayWithMe: false, withDog: false, price: player.price, price9L: player.price9L, payGreenfee: player.payGreenfee, forceGreenfeePayment: player.forceGreenfeePayment, hasAbo: player.hasAbo  })
      //this.pricesum += player.price
      this.searchText = ''
      this.findPlayersRefetch({ searchTerm: ''})
    },
    async findPlayer(searchTerm) {
      if (searchTerm.length > 3) {
        this.findPlayersRefetch({ searchTerm: searchTerm, bookingTypeId: this.bookingTypeId, slotDate: this.slot.date, checkPayment: true})
      }
      else {
        this.findPlayersRefetch({ searchTerm: ''})
      }
    },
    cartChanged() {
      while (this.selectedCart.length > 2)
        this.selectedCart.pop()
      this.pricecarts = 0
      if (this.selectedTeetime.cartPaymentEnabled) {
        for (const cart of this.selectedCart) {
          if (this.slot.carts.findIndex(c => c.cid == cart.cid) >= 0)
            this.pricecarts += this.slot.carts[this.slot.carts.findIndex(c => c.cid == cart.cid)].priceFloat
        }
      }
    },
    checkTime: function (data) {
      if (data.minutes == 0 && data.seconds <= 1)
        this.counting = false
      if (data.minutes == 0 && data.seconds <= 30)
        this.clockColor = 'danger'
      else if (data.minutes == 0 && data.seconds <= 59)
        this.clockColor = 'warning'
    },    
    doBooking() {
      this.buttonDisabled = true
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.createBookingTypeBookingMutation({ dateSlot: this.slot.date, bookingTypeId: this.bookingTypeId, players: JSON.stringify(this.flightPlayers), useFlightIds: false, carts: JSON.stringify(this.selectedCart), paymentType: this.paymenttype,  language: this.$store.getters.language })
      .then(res => {
          toastController
            .create({
              message: this.$t('bookingAdded'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(true)
      })
      .catch(err => {
          loadingController.dismiss()
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + this.$t(err.graphQLErrors[0].message) + ' -> ' + err.graphQLErrors[0].extensions.code,
              duration: 7000,
              color: 'danger'
            }).then(res => { res.present(); this.buttonDisabled = false })
      }) 
    },
    deletePlayer(player) {
      //this.pricesum -= player.price
      this.flightPlayers.splice(this.flightPlayers.findIndex(p => p.id == player.id), 1)
    },
    closeDialog() {
      return modalController.dismiss()
    },
    createBookingByLastschrift(){
      this.buttonDisabled = true
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.createBookingTypeBookingMutation({ dateSlot: this.slot.date, bookingTypeId: this.bookingTypeId, players: JSON.stringify(this.flightPlayers), useFlightIds: false, carts: JSON.stringify(this.selectedCart), paymentType: 'Lastschrift',  language: this.$store.getters.language})
            .then(res => {
                toastController
                  .create({
                    message: this.$t('bookingAdded'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
            }) 
         
    }
  }
});
</script>

<style>
.alert-wrapper {
width: 50% !important;
max-width: 50% !important;
}

.alert-ios .alert-wrapper {
width: 100% !important;
max-width: 100% !important;
}
</style>